<template>
  <div class="infoMaTion">
    <editMyInfomation
      @refreshData="refreshData"
      :resumeInfo="resumeInfo"
    ></editMyInfomation>
  </div>
</template>

<script>
import editMyInfomation from "~bac/components/talent/editMyInfomation";
export default {
  components: { editMyInfomation },
  metaInfo() {
    return {
      title: this.$t("myResume"),
    };
  },
  data() {
    return {
      resumeInfo: "",
      preview: true,
    };
  },
  mounted() {
    this.getMyresume();
  },
  methods: {
    edit() {
      this.preview = false;
    },
    async getMyresume() {
      let data = await this.$store.dispatch(
        "baseConsole/talent_user_getMyresume",
        {
          user_id: this.USER_ID,
        }
      );
      if (data.success) {
        this.resumeInfo = data.data;
      }
    },
    refreshData() {
      this.getMyresume();
    },
  },
};
</script>

<style lang="less" scoped>
.infoMaTion {
  padding: 20px;
}
</style>