<template>
  <div class="editInfo">
    <div class="editBtn">
      <div class="switch">
        {{ $t("isOpen") }}
        <el-switch
          :disabled="switchDisabled"
          @change="switchValue"
          :active-value="1"
          :inactive-value="0"
          v-model="isOpen"
        >
        </el-switch>
      </div>
      <el-button size="small" icon="el-icon-full-screen" @click="previewResume"
        >{{ $t("resumePreview") }}
      </el-button>
    </div>
    <el-form
      ref="ruleForm"
      :show-message="false"
      :model="form"
      label-width="180px"
      label-position="top"
      size="small"
      :rules="rules"
    >
      <div class="editMyInfo">
        <p class="editTitle">{{ $t("editInfo") }}</p>
        <div class="personMsg">
          <div class="txtMsg">
            <div class="msgOne">
              <el-form-item
                :label="$t('name')"
                required
                prop="user_info.name_zh"
              >
                <el-input
                  style="width: 300px"
                  v-model="form.user_info.name_zh"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('gender')" prop="user_info.sex">
                <el-radio-group
                  style="width: 300px"
                  v-model="form.user_info.sex"
                >
                  <el-radio :label="1">{{ $t("male") }}</el-radio>
                  <el-radio :label="2">{{ $t("female") }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="msgTwo">
              <el-form-item
                :label="$t('birtyday')"
                required
                prop="user_info.birthday"
              >
                <el-date-picker
                  style="width: 300px"
                  v-model="form.user_info.birthday"
                  type="date"
                  :placeholder="$t('selectDate')"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('wechat')" prop="user_info.wechat">
                <el-input
                  style="width: 300px"
                  v-model="form.user_info.wechat"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="headImg">
            <el-avatar
              class="img"
              shape="circle"
              :size="100"
              :fit="'contain'"
              :src="
                form.user_info.avatar ? form.user_info.avatar : defaultAvatar
              "
            ></el-avatar>
            <fileUpload
              @uploadCompleteOSS="uploadimg"
              :needCropImg="fileUploadOptions.needCropImg"
              :aspectRatio="fileUploadOptions.aspectRatio"
              :accept="fileUploadOptions.accept"
            >
              <el-button type="primary" icon="el-icon-upload" size="small"
                >{{ $t("changeheadportrait") }}
              </el-button>
            </fileUpload>
          </div>
        </div>
        <div class="address">
          <el-form-item
            :label="$t('location')"
            :prop="form.resume_info['city_' + LOCALE]"
            required
            class="address clearfix"
          >
            <CountrySelect
              style="width: 162px; margin-right: 15px"
              class="fl"
              v-model="form.resume_info.country"
              :locale="LOCALE"
            ></CountrySelect>
            <CityComplete
              style="width: 264px"
              class="fl"
              v-model="form.resume_info['city_' + LOCALE]"
              :locale="LOCALE"
              :placeTitle="$t('pleaseSelectCity')"
              :countryId="form.resume_info.country"
            ></CityComplete>
          </el-form-item>
        </div>
        <div class="concatMethed">
          <el-form-item :label="$t('phone')">
            <el-input
              style="width: 300px"
              disabled
              v-model="form.user_info.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('email')" required prop="user_info.email">
            <el-input
              style="width: 300px"
              v-model="form.user_info.email"
            ></el-input>
          </el-form-item>
        </div>
        <p class="tips">
          {{ $t("logintips")
          }} <span @click="gotoSet">{{ $t("accountSet") }}</span>
          <!-- 中修改 -->
        </p>
      </div>
      <div class="editAdvantage">
        <p class="editTitle">{{ $t("editEdvantage") }}</p>
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="$t('advantageEnter')"
          v-model="form.resume_info.advantage"
          maxlength="140"
          resize="none"
          show-word-limit
        >
        </el-input>
      </div>
      <div class="addJob">
        <p class="editTitle">{{ $t("expectJob") }}</p>
        <div
          class="jobBox"
          v-for="(jobItem, jobIndex) in form.expected_position_info"
          :key="jobIndex"
        >
          <i class="el-icon-remove" @click="deleteJob(jobIndex)"></i>
          <div class="jobOne">
            <el-form-item :label="$t('jobType')">
              <el-select
                style="width: 300px"
                v-model="jobItem.position_kind"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in jobType"
                  :key="item.value"
                  :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('expectJob')">
              <el-select
                style="width: 300px"
                v-model="jobItem.position_type"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in positionTypeList"
                  :key="item.value"
                  :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="jobTwo">
            <el-form-item :label="$t('salaryRequire')">
              <div class="money">
                <el-select
                  style="width: 126px"
                  v-model="jobItem.salary_lowest"
                  :placeholder="$t('pleaseSelect')"
                >
                  <el-option
                    v-for="item in wages"
                    :key="item.value"
                    :label="item.label_zh"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span>{{ $t("to") }}</span>
                <el-select
                  style="width: 126px"
                  v-model="jobItem.salary_highest"
                  :placeholder="$t('pleaseSelect')"
                >
                  <el-option
                    v-for="item in wages"
                    :key="item.value"
                    :label="item.label_zh"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item :label="$t('city1')">
              <CityComplete
                style="width: 300px"
                class="fl"
                v-model="jobItem['city_' + LOCALE]"
                :locale="LOCALE"
                :placeTitle="$t('pleaseSelectCity')"
                :countryId="1"
              ></CityComplete>
            </el-form-item>
          </div>
        </div>
        <div class="addBtns clearfix">
          <el-button
            class="fr"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addNewJob"
            >{{ $t("addNewJob") }}
          </el-button>
        </div>
      </div>
      <div class="addJob">
        <p class="editTitle">{{ $t("addEducational") }}</p>
        <div
          class="jobBox"
          v-for="(schoolItem, schoolIndex) in form.educational_experience_info"
          :key="schoolIndex"
        >
          <i
            class="el-icon-remove"
            v-if="schoolIndex > 0"
            @click="deleteSchool(schoolIndex)"
          ></i>
          <div class="jobOne">
            <el-form-item :label="$t('schoolName')">
              <el-input
                style="width: 300px"
                v-model="schoolItem.school_name"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('education')">
              <el-select
                style="width: 160px; margin-right: 8px"
                v-model="schoolItem.level"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in levelRequire"
                  :key="item.value"
                  :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                style="width: 126px"
                v-model="schoolItem.class_type"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in schoolType"
                  :key="item.value"
                  :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="jobTwo">
            <el-form-item :label="$t('majorName')">
              <el-input
                style="width: 300px"
                v-model="schoolItem.major"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('timeQuan')">
              <el-date-picker
                @blur="timesChange"
                v-model="schoolItem.times"
                type="monthrange"
                style="width: 300px"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('enterDate')"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              :label="$t('experienceSchool') + '（' + $t('optional') + '）'"
            >
              <el-input
                type="textarea"
                :rows="5"
                :placeholder="$t('pleaseEnterExperience')"
                v-model="schoolItem.experience"
                maxlength="140"
                resize="none"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="addBtns clearfix">
          <el-button
            class="fr"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addSchool"
            >{{ $t("addEducational") }}
          </el-button>
        </div>
      </div>
      <div class="addJob">
        <p class="editTitle">{{ $t("addJob") }} / {{ $t("internship") }}</p>
        <div
          class="jobBox"
          v-for="(schoolItem, schoolIndex) in form.work_experience_info"
          :key="schoolIndex"
        >
          <i class="el-icon-remove" @click="deleteWork(schoolIndex)"></i>
          <div class="jobOne">
            <el-form-item :label="$t('Company_n')">
              <el-input
                style="width: 300px"
                v-model="schoolItem.company_name"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('positionType')">
              <el-select
                style="width: 300px"
                v-model="schoolItem.position_type"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in positionTypeList"
                  :key="item.value"
                  :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="jobTwo">
            <el-form-item :label="$t('jobName')">
              <el-input
                style="width: 300px"
                v-model="schoolItem.position_name"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('industryTitle')">
              <el-input
                style="width: 300px"
                v-model="schoolItem.department_name"
              ></el-input>
            </el-form-item>
          </div>
          <div class="jobTwo">
            <el-form-item :label="$t('workingLife')">
              <el-date-picker
                @blur="timesChange"
                v-model="schoolItem.times"
                type="monthrange"
                style="width: 300px"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('enterDate')"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item :label="$t('jobDescrip')">
              <el-input
                type="textarea"
                :rows="5"
                :placeholder="$t('pleaseJobDescrip')"
                v-model="schoolItem.experience"
                maxlength="140"
                resize="none"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="addBtns clearfix">
          <el-button
            class="fr"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addWork"
            >{{ $t("addJob") }} / {{ $t("internship") }}
          </el-button>
        </div>
      </div>
      <div class="addCertificate addJob">
        <p class="editTitle">{{ $t("addCertificate") }}</p>
        <fileUpload @uploadCompleteOSS="uploadCertificate" :autoUpload="true">
          <p ref="uploadBtn"></p>
        </fileUpload>
        <div
          class="jobBox"
          v-for="(
            certificateItem, certificateIndex
          ) in form.job_certificate_info"
          :key="certificateIndex"
        >
          <i
            class="el-icon-remove"
            @click="deleteCertificate(certificateIndex)"
          ></i>
          <div class="jobOne">
            <el-form-item :label="$t('certificateName')">
              <el-input
                style="width: 300px"
                v-model="certificateItem.name"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('certificateName')" style="width: 300px">
              <el-button
                v-if="!certificateItem.attachment_list.length"
                icon="el-icon-upload"
                @click="upCertificate(certificateIndex)"
                plain
                >{{ $t("uploadAttach") }}
              </el-button>

              <p
                v-else
                class="certificateItemFile"
                style="
                  width: 300px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span class="name">
                  <img
                    :src="
                      certificateItem.attachment_list[0].file_type
                        | enclosureImgbyTypeFormat
                    "
                    alt=""
                  />
                  <span>
                    {{ certificateItem.attachment_list[0].file_name }}</span
                  ></span
                >
                <span
                  ><i
                    @click="deletecertificateItemBaseFile(certificateIndex)"
                    class="el-icon-circle-close"
                  ></i
                ></span>
              </p>
            </el-form-item>
          </div>
        </div>
        <div class="addBtns clearfix">
          <el-button
            class="fr"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addCertificate"
            >{{ $t("addCertificate") }}
          </el-button>
        </div>
        <div class="addCertificate">
          <p class="editTitle">{{ $t("addFile") }}</p>
          <div class="jobBox">
            <div class="">
              <el-form-item
                :label="$t('uploadAttach') + '（' + $t('optional') + '）'"
                required
              >
                <fileUpload
                  :isDisabled="
                    form.resume_info.attachment_list.length ? true : false
                  "
                  @uploadCompleteOSS="unLoadBaseFile"
                  accept=".pdf, .doc, .docx"
                  :autoUpload="true"
                >
                  <el-button
                    icon="el-icon-upload"
                    :disabled="
                      form.resume_info.attachment_list.length ? true : false
                    "
                    plain
                    >{{ $t("uploadFiles") }}
                  </el-button>
                </fileUpload>
              </el-form-item>
              <div
                class="fileBase"
                v-if="form.resume_info.attachment_list.length"
              >
                <p>
                  <img
                    :src="
                      form.resume_info.attachment_list[0].file_type
                        | enclosureImgbyTypeFormat
                    "
                    alt=""
                  />
                  <span>{{
                    form.resume_info.attachment_list[0].file_name
                  }}</span>
                </p>
                <i class="el-icon-delete" @click="deleteBaseFile"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lastbtns clearfix" style="margin-top: 20px">
        <el-button
          type="primary"
          class="fr"
          size="small"
          style="margin-left: 20px"
          @click="submitForm('ruleForm')"
          :loading="loading"
          >{{ $t("save") }}
        </el-button>
        <!-- <el-button class="fr" size="small">取消</el-button> -->
      </div>
    </el-form>
    <el-dialog
      :title="$t('resumePreview')"
      :visible.sync="previewResumeDialog"
      width="800px"
    >
      <myInfomation
        v-if="previewResumeDialog"
        :resumeInfo="showform"
      ></myInfomation>
    </el-dialog>
  </div>
</template>

<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CityComplete from "~/baseComponents/CityComplete";
import fileUpload from "~/baseComponents/fileUpload";
import { getenclosureType, getFileType } from "~/basePlugins/filters";
import {
  jobType,
  positionTypeList,
  wages,
  schoolType,
  levelRequire,
} from "~scr/utils/enumValue";
import { priorFormat } from "@/basePlugins/filters";
import myInfomation from "./myInfomation";
import { objectValueAllEmpty } from "~/baseUtils";
import { getCityIdByName, getCityNameById } from "~/baseUtils/AddressConfig";

export default {
  props: {
    resumeInfo: {
      type: Object | String,
    },
  },
  components: { CountrySelect, CityComplete, fileUpload, myInfomation },
  data() {
    return {
      previewResumeDialog: false,
      fileUploadOptions: {
        needCropImg: true,
        aspectRatio: 20 / 20,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      showform: {},
      loading: false,
      wages,
      jobType: jobType,
      positionTypeList,
      schoolType,
      levelRequire,
      rules: {},
      form: {
        resume_info: {
          country: 1,
          city: "",
          is_public: 1,
          advantage: "",
          attachment_list: [],
        },
        user_info: {
          user_id: "",
          name_zh: "",
          name_en: "",
          sex: 1,
          birthday: "",
          mobile: "",
          email: "",
          wechat: "",
          avatar: "",
        },
        expected_position_info: [],
        work_experience_info: [],
        educational_experience_info: [
          {
            school_name: "",
            level: "",
            class_type: "",
            major: "",

            times: [],
            experience: "",
          },
        ],
        job_certificate_info: [],
      },
      certificateIndex: 0,
      isOpen: 0,
    };
  },
  mounted() {
    console.log(this.jobType);
    this.getMyresume();
    this.form.user_info.mobile = this.USER_INFO.mobile;

    setTimeout((res) => {
      this.$refs["ruleForm"].clearValidate();
    }, 0);
  },
  watch: {
    resumeInfo(nv) {
      this.getMyresume();
      if (this.resumeInfo.resume_info) {
        this.isOpen = this.resumeInfo.resume_info.is_public;
      }
    },
  },
  computed: {
    switchDisabled() {
      return !this.resumeInfo.resume_info;
    },
  },
  methods: {
    timesChange() {
      this.$forceUpdate();
    },
    gotoSet() {
      this.$router.push("/console/account/security");
    },
    previewResume() {
      let showform = JSON.parse(JSON.stringify(this.form));
      showform.user_info.birthday = this.$moment(
        showform.user_info.birthday
      ).unix();
      this.showform = showform;
      this.previewResumeDialog = true;
    },
    getMyresume() {
      this.form.user_info.name_zh =
        this.USER_INFO.name_zh || this.USER_INFO.name_en;
      if (objectValueAllEmpty(this.resumeInfo)) {
        return;
      }
      let info = this.resumeInfo;
      if (info.user_info) {
        info.user_info.birthday = this.$moment(
          info.user_info.birthday * 1000
        ).format("YYYY-MM-DD");
      }

      info.work_experience_info.forEach((ee, vv) => {
        if (ee.position_type == 0) {
          ee.position_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times[0] = new Date(
            this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
          );
          ee.times[1] = new Date(
            this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
          );
        } else {
          ee.times = [];
        }
      });
      info.educational_experience_info.forEach((ee, vv) => {
        if (ee.level == 0) {
          ee.level = "";
        }
        if (ee.class_type == 0) {
          ee.class_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times.push(
            new Date(this.$moment(ee.start_time * 1000).format("YYYY-MM-DD"))
          );
          ee.times.push(
            new Date(this.$moment(ee.end_time * 1000).format("YYYY-MM-DD"))
          );
        } else {
          ee.times = [];
        }
      });

      this.form = info;
    },
    uploadimg(result) {
      this.form.user_info.avatar = result.url;
    },
    unLoadBaseFile(result) {
      this.form.resume_info.attachment_list.push({
        file_name: result.name,
        url: result.url,
        file_type: getenclosureType(getFileType(result.url)),
      });
    },
    addNewJob() {
      this.form.expected_position_info.push({
        position_kind: "",
        position_type: "",
        salary_requirements: 2,
        salary_lowest: "",
        salary_highest: "",
        country: 1,
        city: "",
      });
    },
    addSchool() {
      this.form.educational_experience_info.push({
        name: "",
        education: "",
        type: "",
        major: "",
        times: [],
        desc: "",
      });
    },
    addWork() {
      this.form.work_experience_info.push({
        company_name: "",
        department_name: "",
        position_name: "",
        position_type: "",
        position_kind: "1",
        times: [],

        experience: "",
      });
    },
    addCertificate() {
      this.form.job_certificate_info.push({
        name: "",
        attachment_list: [],
      });
    },
    upCertificate(index) {
      this.certificateIndex = index;
      this.$refs.uploadBtn.click();
    },
    uploadCertificate(result) {
      this.form.job_certificate_info[
        this.certificateIndex
      ].attachment_list.push({
        file_name: result.name,
        url: result.url,
        file_type: getenclosureType(getFileType(result.url)),
      });
    },
    deleteJob(index) {
      this.form.expected_position_info.splice(index, 1);
    },
    deleteSchool(index) {
      this.form.educational_experience_info.splice(index, 1);
    },
    deleteBaseFile() {
      this.form.resume_info.attachment_list = [];
    },
    deleteWork(index) {
      this.form.work_experience_info.splice(index, 1);
    },
    deletecertificateItemBaseFile(index) {
      this.form.job_certificate_info[index].attachment_list = [];
    },
    deleteCertificate(index) {
      this.form.job_certificate_info.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.resume_info.source = this.PJSource;
          let parmas = JSON.parse(JSON.stringify(this.form));
          parmas.resume_info.is_public = this.isOpen;
          parmas.educational_experience_info =
            parmas.educational_experience_info.filter((item) => {
              return !objectValueAllEmpty(item);
            });
          parmas.work_experience_info = parmas.work_experience_info.filter(
            (item) => {
              return !objectValueAllEmpty(item);
            }
          );
          parmas.expected_position_info = parmas.expected_position_info.filter(
            (item) => {
              return !objectValueAllEmpty(item);
            }
          );
          parmas.job_certificate_info = parmas.job_certificate_info.filter(
            (item) => {
              return !objectValueAllEmpty(item);
            }
          );
          if (!this.form.resume_info.advantage) {
            this.$message({
              message: this.$t("addEdvantage"),
              type: "warning",
            });
            return;
          }
          if (!parmas.expected_position_info.length) {
            this.$message({
              message: this.$t("expectJob"),
              type: "warning",
            });
            return;
          }
          if (!parmas.educational_experience_info.length) {
            this.$message({
              message: this.$t("addEducational"),
              type: "warning",
            });
            return;
          }

          if (!parmas.resume_info.attachment_list.length) {
            this.$message({
              message: this.$t("addAttachment"),
              type: "warning",
            });
            return;
          }
          if (parmas.work_experience_info.length) {
            parmas.work_experience_info.forEach((ee, vv) => {
              if (ee.times.length) {
                ee.start_time = this.$moment(ee.times[0]).unix();
                ee.end_time = this.$moment(ee.times[1]).unix();
              }
            });
          }
          if (parmas.educational_experience_info.length) {
            parmas.educational_experience_info.forEach((ee, vv) => {
              if (ee.times.length) {
                ee.start_time = this.$moment(ee.times[0]).unix();
                ee.end_time = this.$moment(ee.times[1]).unix();
              }
            });
          }
          parmas.user_info.birthday = this.$moment(
            parmas.user_info.birthday
          ).unix();
          parmas.user_info.user_id = this.USER_ID;
          if (parmas.resume_info.resume_id) {
            parmas.resume_id = parmas.resume_info.resume_id;
            delete parmas.resume_info.resume_id;
          }
          parmas.expected_position_info.forEach((item) => {
            item.city = getCityIdByName(
              item["city_" + this.LOCALE],
              this.LOCALE
            );
            delete item.city_en;
            delete item.city_zh;
          });
          parmas.resume_info.city = getCityIdByName(
            parmas.resume_info["city_" + this.LOCALE],
            this.LOCALE
          );
          delete parmas.resume_info.city_en;
          delete parmas.resume_info.city_zh;
          parmas.user_info = JSON.stringify(parmas.user_info);
          parmas.resume_info = JSON.stringify(parmas.resume_info);
          parmas.expected_position_info = JSON.stringify(
            parmas.expected_position_info
          );
          parmas.work_experience_info = JSON.stringify(
            parmas.work_experience_info
          );
          parmas.educational_experience_info = JSON.stringify(
            parmas.educational_experience_info
          );
          parmas.job_certificate_info = JSON.stringify(
            parmas.job_certificate_info
          );
          this.loading = true;
          let data = await this.$store.dispatch(
            "baseConsole/talent_user_resumeEdit",
            parmas
          );
          if (data.success) {
            this.loading = false;
            this.$message({
              message: this.$t("sace_success"),
              type: "success",
            });
            this.$emit("refreshData");
          }
        } else {
          return false;
        }
      });
    },
    async switchValue(e) {
      let params = {};
      params.is_public = e;
      params.resume_id = this.resumeInfo.resume_info.resume_id;
      let data = await this.$store.dispatch(
        "baseConsole/talent_update_resume",
        params
      );
      if (data.success) {
        this.$message({
          message: this.$t("changeSuccess"),
          type: "success",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 18px;
}

.personMsg {
  display: flex;
  justify-content: space-between;

  .txtMsg {
    width: 624px;

    .msgOne,
    .msgTwo {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .headImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 150px;

    .img {
      margin-bottom: 15px;
    }
  }
}

.address {
}

.concatMethed {
  width: 624px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tips {
  font-size: 12px;
  color: #999;
  padding-bottom: 18px;
  border-bottom: 1px solid #e8e8e8;

  span {
    color: #027fff;
    cursor: pointer;
  }
}

.editAdvantage {
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

.addJob {
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;

  .jobOne,
  .jobTwo {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .money {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.jobBox {
  position: relative;

  .el-icon-remove {
    position: absolute;
    right: 50px;
    top: 0;
    font-size: 20px;
    cursor: pointer;
    color: #e6a23c;
  }
}

.addCertificate {
}

.fileBase {
  width: 624px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    display: flex;
    align-items: center;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .el-icon-delete {
    cursor: pointer;
  }
}

.certificateItemFile {
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .name {
    display: flex;
    align-items: center;

    span {
      width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }
  }

  .el-icon-circle-close {
    cursor: pointer;
  }
}

.editBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .switch {
    margin-right: 40px;
  }
}
/deep/.el-range-editor--small .el-range-separator {
  width: 20px;
}
</style>
